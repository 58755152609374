"use client";

import { useRef } from "react";

import Select from "@/components/primitives/select";
import Icon from "@/components/primitives/icon";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import Button from "../primitives/button";
import Card from "../primitives/card";
import {
  FeaturedPicks as FeaturedPicksType,
  LineType,
  OddVariant,
  PickType,
  Sport,
  formatLineType,
  formatOddVariant,
  getPickAlignment,
  pickToOdd,
  pickTypeToLineType,
} from "common";
import { ALL_ODD_OPTIONS } from "@/data/sports/options";
import React from "react";
import { useWebSocketMessage } from "@/utils/hooks/websocket-message";
// import useFetch from "@/utils/fetch";
import { formatTime, formatOdd, formatServerDate } from "@/utils";
import TeamLogo from "../team/logo";
import Avatar from "../bettor/avatar";
import ReactTimeAgo from "react-time-ago";
import Tooltip from "../primitives/tooltip";
import Link from "next/link";
import { createGameDetailSlug, createProfileSlug } from "@/utils/url";
import { LoadingCircle } from "../primitives/loading";
import { useOddsFormat } from "@/utils/providers/odds-format";

import useFetch from "@/utils/fetch";

const sportsOptions = [
  {
    value: "all-sports",
    label: "All Sports",
  },
  {
    value: "nfl",
    label: "NFL",
    icon: "football",
    count: "(6)",
  },
  {
    value: "nba",
    label: "NBA",
    icon: "basketball",
    count: "(6)",
  },
  {
    value: "mlb",
    label: "MLB",
    icon: "baseball",
    count: "(6)",
  },
  {
    value: "nhl",
    label: "NHL",
    icon: "hockey-puck",
    count: "(0)",
    countGray: true,
  },
];

const pickTypes = [
  {
    value: "all-odds",
    label: "All Odds",
  },
  {
    value: "spread",
    label: "Spread",
  },
  {
    value: "total",
    label: "Total",
  },
  {
    value: "moneyline",
    label: "Moneyline",
  },
];
interface FeaturedPicksProps {
  sport?: Sport;
  odd?: OddVariant;
  game?: number;
}
export default function FeaturedPicks(props: FeaturedPicksProps) {
  const { oddsFormat } = useOddsFormat();

  const blockRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [selectedOdd, setSelectedOdd] = React.useState<OddVariant>(
    props.odd || "all"
  );

  const wsFeaturedPicks = useWebSocketMessage("featured-picks");
  console.log("featured picks from ws:", wsFeaturedPicks);
  const base_url: string = `${process.env.NEXT_PUBLIC_API_URL}/api/picks/featured-picks`;
  const params: URLSearchParams = new URLSearchParams();
  if (props.sport) {
    params.append("sport", props.sport);
  } else if (props.game) {
    params.append("gameId", `${props.game}`);
  }
  if (selectedOdd !== "all") {
    params.append("odd", selectedOdd);
  }

  // Upload and Update data when the card in viewport

  const request: string = `${base_url}?${params}`;
  const { data, isLoading, isError, error } = useFetch<any | null>(
    null,
    request,
    []
  );

  if (data && !isLoading) {
    data.slice(0, 10);
    return (
      <>
        <section className="pt-10 desktop-grid:pt-6" ref={blockRef}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-6 flex-wrap overflow-y-hidden overflow-x-auto container">
              <div>
                <h1 className="text-headline-lg uppercase flex items-center gap-1">
                  <Icon name="diamond" size="lg" className="fill-primary" />
                  Featured Picks
                  <Tooltip>
                    <span className="text-center max-w-[100px]">
                      Picks made by other bettors of all experience levels.
                      Create picks with a chance to get featured!
                    </span>
                  </Tooltip>
                </h1>
              </div>

              <div className="flex items-center gap-4 min-w-[552px] relative">
                {/* {!props.sport && (
                <Select
                  label="Sport"
                  options={ALL_SPORT_OPTIONS}
                  defaultValue={ALL_SPORT_OPTIONS[0].value}
                />
              )} */}

                <Select
                  label="Odds"
                  options={ALL_ODD_OPTIONS}
                  value={selectedOdd}
                  onValueChange={(v) => setSelectedOdd(v as OddVariant)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 mb-6 container">
            <Splide
              hasTrack={false}
              options={{ rewind: true, autoWidth: true, pagination: false }}
            >
              {/* <div className="absolute -right-[220px] w-[320px] h-[240px] top-[16px] z-[3] bg-[linear-gradient(270deg,#F4F4F6_65.15%,rgba(244,244,246,0.00)100%)]"></div> */}
              <SplideTrack className="-mt-6 pt-6 pb-16 -mx-3 desktop-grid:-mx-6 !px-3 desktop-grid:!px-6 !mr-0">
                {isLoading ? (
                  <div className="flex justify-center items-center h-[210px] w-full">
                    <LoadingCircle />
                  </div>
                ) : (
                  data && (
                    <>
                      {data.map((pick: any, i: number) => {
                        const alignment = getPickAlignment(pick.type);
                        const lineType = pickTypeToLineType(pick.type);
                        const formattedLine = formatLineType(lineType);
                        const odd = pickToOdd(pick);
                        const formattedOdd = formatOdd(oddsFormat, odd);
                        return (
                          <SplideSlide key={i}>
                            <Link
                              href={createGameDetailSlug(
                                pick.game.league,
                                pick.game.id
                              )}
                            >
                              <Card
                                custom
                                dark
                                className="p-4 w-[290px] min-h-[205px] gap-4 mr-2"
                              >
                                <div className="flex flex-col items-center justify-center gap-1">
                                  <div className="text-light text-headline-medium capitalize">
                                    {formattedLine}
                                  </div>
                                  <div className="text-label-lg text-secondary">
                                    {formatTime(pick.game.dateTime)} -{" "}
                                    {pick.game.platform}
                                  </div>
                                </div>
                                <div className="grid grid-cols-3 gap-3 relative z-[1] h-[53px]">
                                  <div className="flex flex-col justify-center items-center">
                                    <div className="z-[-1] absolute w-[70%] object-fill left-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none">
                                      <img
                                        src={pick.game.awayTeam.logoUrl}
                                        alt="Logo Splash"
                                        className="w-full h-full"
                                      />
                                    </div>
                                    <div className="-mt-6 mb-2 z-[1]">
                                      <div className="relative">
                                        <TeamLogo
                                          team={pick.game.awayTeam}
                                          sport={pick.game.league}
                                        />
                                      </div>
                                    </div>
                                    <div className="text-title-odds text-white">
                                      {pick.game.awayTeam.abbreviation}
                                    </div>
                                  </div>

                                  <Card
                                    custom
                                    className="!rounded-md gap-1 w-full h-[53px]"
                                  >
                                    {pick.type === PickType.Over ? (
                                      <div className="flex justify-center top-[8px] relative items-center gap-1">
                                        <Icon
                                          name="arrow-up"
                                          className="fill-primary-400"
                                          size="xs"
                                        />
                                        <div className="text-label-lg-bold text-secondary-300 uppercase">
                                          Over
                                        </div>
                                      </div>
                                    ) : pick.type === PickType.Under ? (
                                      <div className="flex justify-center top-[8px] relative items-center gap-1">
                                        <Icon
                                          name="arrow-down"
                                          className="fill-hot"
                                          size="xs"
                                        />
                                        <div className="text-label-lg-bold text-secondary-300 uppercase">
                                          Under
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex justify-center items-center gap-2 relative">
                                        <div className="w-6 h-6 absolute flex justify-center items-center left-2 top-1">
                                          <img
                                            src={
                                              alignment === 0
                                                ? pick.game.awayTeam.logoUrl
                                                : pick.game.homeTeam.logoUrl
                                            }
                                            alt="Logo Splash"
                                            className="w-full h-full"
                                          />
                                        </div>
                                        <div className="flex flex-col absolute top-2 right-3 min-w-[24px]">
                                          <div className="text-title-lg-medium text-dark">
                                            {alignment === 0
                                              ? pick.game.awayTeam.abbreviation
                                              : pick.game.homeTeam.abbreviation}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="flex items-center gap-1 absolute bottom-2 left-0 right-0 min-w-[56px] justify-center">
                                      <div className="text-title-sm-bold text-dark">
                                        {lineType === LineType.Moneyline
                                          ? formattedOdd?.payout
                                          : formattedOdd?.value}
                                      </div>
                                      <div className="text-label-bold text-secondary-400">
                                        {lineType === LineType.Moneyline
                                          ? undefined
                                          : formattedOdd?.payout}
                                      </div>
                                    </div>
                                  </Card>
                                  <div className="flex flex-col justify-center items-center">
                                    <div className="z-[-1] absolute w-[70%] h-[180px] object-fill right-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none">
                                      <img
                                        src={pick.game.homeTeam.logoUrl}
                                        alt="Logo Splash"
                                        className="w-full h-full"
                                      />
                                    </div>
                                    <div className="-mt-6 mb-2 z-[1]">
                                      <div className="relative">
                                        <TeamLogo
                                          team={pick.game.homeTeam}
                                          sport={pick.game.league}
                                        />
                                      </div>
                                    </div>
                                    <div className="text-title-odds text-white">
                                      {pick.game.homeTeam.abbreviation}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-col items-center">
                                  <div className="text-label-sm-medium uppercase text-secondary mb-1">
                                    <Icon
                                      name="clock"
                                      size="xs"
                                      className="fill-secondary"
                                    ></Icon>{" "}
                                    Pick created{" "}
                                    <ReactTimeAgo
                                      date={new Date(pick.createdAt)}
                                    ></ReactTimeAgo>
                                  </div>
                                  <Link
                                    href={createProfileSlug(
                                      pick.bettor.displayName
                                    )}
                                    className="flex flex-col items-center justify-center gap-2"
                                  >
                                    <div className="flex flex-row gap-2 items-center mt-2">
                                      <Avatar
                                        size="xs"
                                        image={pick.bettor.avatarUrl}
                                      />
                                      <div className="text-label-lg-medium text-white">
                                        {pick.bettor.displayName}
                                      </div>
                                    </div>
                                    <div className="text-label-lg text-secondary uppercase">
                                      {pick.record?.wins || 0}-
                                      {pick.record?.losses || 0}-
                                      {pick.record?.ties || 0} {pick.sport}{" "}
                                      Record |{" "}
                                      {pick.units > 0
                                        ? `+${pick.units}`
                                        : pick.units || 0}{" "}
                                      Units
                                    </div>
                                  </Link>
                                </div>
                              </Card>
                            </Link>
                          </SplideSlide>
                        );
                      })}
                    </>
                  )
                )}
              </SplideTrack>
              <div className="splide__arrows absolute flex gap-2 justify-center items-center -top-[5.8rem] min-[422px]:-top-[2.3rem] -right-3 pr-3 z-[4]">
                <Button
                  variant="circle"
                  color="secondary"
                  size="sm"
                  className={`splide__arrow splide__arrow--prev`}
                >
                  <Icon
                    name="arrow-left"
                    className="fill-dark"
                    size="sm"
                  ></Icon>
                </Button>
                <Button
                  variant="circle"
                  color="secondary"
                  size="sm"
                  className={`splide__arrow splide__arrow--next`}
                >
                  <Icon
                    name="arrow-right"
                    className="fill-dark"
                    size="sm"
                  ></Icon>
                </Button>
              </div>
            </Splide>
          </div>
        </section>
        <div className="-mt-12"></div>
      </>
    );
  }
  return undefined;
}

function FeaturedPick() {
  return (
    <Card custom dark className="p-4 w-[18.125rem] min-h-[14rem] gap-5 mr-2">
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-light text-headline-medium">Spread</div>
        <div className="text-label-lg text-secondary">4:30ET - FOX</div>
      </div>
      <div className="grid grid-cols-3 gap-3 relative z-[1]">
        <div className="flex flex-col justify-center items-center">
          <img
            src="/images/teams/Team=ARI.svg"
            alt=""
            className="z-[0] absolute w-[70%] object-fill left-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none"
          />
          <img
            src="/images/teams/Team=ARI.svg"
            alt=""
            className="w-full -mt-10 z-[1]"
          />
          <div className="text-title-odds text-white">ARI</div>
        </div>
        <Card custom className="!rounded-md gap-1">
          <div className="flex justify-center items-center gap-2 relative">
            <img
              src="/images/teams/Team=TEN.svg"
              alt=""
              className="w-6 h-6 absolute left-2 top-1"
            />
            <div className="flex flex-col absolute top-2 right-3">
              <div className="text-title-lg-medium text-dark">TEN</div>
            </div>
          </div>
          <div className="flex items-center gap-1 absolute bottom-2 left-3">
            <div className="text-label-bold text-dark">-13.5</div>
            <div className="text-label-sm-bold text-secondary-400">-117</div>
          </div>
        </Card>
        <div className="flex flex-col justify-center items-center">
          <img
            src="/images/teams/Team=TEN.svg"
            alt=""
            className="absolute w-[70%] object-fill right-[-30%] top-[-200%] opacity-10 max-w-none pointer-events-none"
          />
          <img
            src="/images/teams/Team=TEN.svg"
            alt=""
            className="w-full -mt-10 z-[1]"
          />
          <div className="text-title-odds text-white">TEN</div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <Icon variant="circle" name="" className="bg-error-300" size="sm" />
        <div className="text-label-lg-medium text-white">GrandSlamAce16</div>
        <div className="text-label-lg text-secondary">+44100 Units</div>
      </div>
    </Card>
  );
}
